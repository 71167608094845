<template>
  <a href="javascript:" :class="classes" @click="clickLoginCheckButton">
    <slot></slot>
  </a>
</template>

<script>
import {MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM} from '../../store/_mut_consts';

export default {
  name: 'LoginCheckLink',
  props:{
    classes: Array,
    isAuth: Boolean,
    clickFunc: Function
  },
  methods: {
    clickLoginCheckButton(){
      if(this.isAuth){
        this.clickFunc();
      }else{
        this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: '회원가입 및 로그인이 필요합니다.',
          html: true,
          showCancelButton: true,
          yesfunc: () => {
            this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
            this.$router.push({name:'Login'});
          },
        });
      }
    }
  }

};
</script>